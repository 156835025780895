.jackpot-section {
    position: relative;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.jackpot-container {
    position: absolute;
    top: 50%;
    left: 50%;
    /* Center on all screen sizes */
    transform: translate(-50%, -50%);
    z-index: 1;
}

.live-jackpot-text {
    font-size: 1.5rem;
    /* Base font size */
    font-weight: bold;
    color: #FF0000;
    /* Red color for the text */
    text-shadow: 0 0 5px #FF6347, 0 0 10px #FF6347;
    /* Reduced glowing red outline */
}

.jackpot-number {
    font-size: 2rem;
    /* Base font size */
    font-weight: bold;
    color: #000000;
    /* Black color for the number */
    letter-spacing: 0.05em;
    /* Increase space between letters */
    text-shadow: 0 0 5px #FFD700, 0 0 10px #FFD700;
    /* Reduced glowing gold outline */
}

.responsive-img {
    max-width: 100%;
    /* Ensure the image is responsive */
    height: auto;
    margin: 0 auto;
    /* Center image horizontally */
}

/* Extra small screens (up to 575px) */
@media (max-width: 575px) {
    .live-jackpot-text {
        font-size: 10px;
        /* Smaller font size */
    }

    .jackpot-number {
        font-size: 10px;
        /* Smaller font size */
    }

    .jackpot-container {
        left: 48%;
        /* Center it more on smaller screens */
        transform: translate(-30%, -50%);
    }
}

/* Small screens (≥576px) */
@media (min-width: 576px) {
    .live-jackpot-text {
        font-size: 1.2rem;
        /* Slightly larger font size */
    }

    .jackpot-number {
        font-size: 1.4rem;
        /* Slightly larger font size */
    }
}

/* Medium screens (≥768px) */
@media (min-width: 768px) {
    .live-jackpot-text {
        font-size: 1.4rem;
        /* Larger font size */
    }

    .jackpot-number {
        font-size: 1.6rem;
        /* Larger font size */
    }

    .jackpot-container {
        left: 48%;
        /* Center it more on smaller screens */
        transform: translate(-30%, -50%);
    }
}

/* Large screens (≥992px) */
@media (min-width: 992px) {
    .live-jackpot-text {
        font-size: 1.6rem;
        /* Larger font size */
    }

    .jackpot-number {
        font-size: 1.8rem;
        /* Larger font size */
    }

    .jackpot-container {
        left: 48%;
        /* Center it more on smaller screens */
        transform: translate(-30%, -50%);
    }
}

/* Extra large screens (≥1200px) */
@media (min-width: 1200px) {
    .live-jackpot-text {
        font-size: 1.8rem;
        /* Larger font size */
    }

    .jackpot-number {
        font-size: 2rem;
        /* Larger font size */
    }
}

/* Extra extra large screens (≥1400px) */
@media (min-width: 1400px) {
    .live-jackpot-text {
        font-size: 2rem;
        /* Larger font size */
    }

    .jackpot-number {
        font-size: 2.2rem;
        /* Larger font size */
    }
}