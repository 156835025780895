/* Carousel.css */

/* Custom styles for the carousel */
.carousel-item {
  text-align: center;
  background-color: #f8f9fa;
  /* Light grey background */
}

.card {
  position: relative;
  overflow: hidden;
  height: 100%;
  /* Ensure cards take full height of their container */
}

.card-img {
  width: 100%;
  height: 200px;
  /* Fixed height for the image */
  background-repeat: no-repeat;
  /* Prevent image from repeating */
  background-position: center center;
  /* Center the background image */
  background-color: black;
  /* Fallback background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background for captions */
  color: #ffffff;
  /* White text for captions */
  padding: 20px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  /* Hide by default */
}

.card:hover .card-body {
  opacity: 1;
  /* Show caption on hover */
}

.card-title {
  font-size: 1.25rem;
}

.card-text {
  color: #dddddd;
  /* Light grey text for descriptions */
}

.btn-primary {
  margin-top: 10px;
  /* Adjust button position as needed */
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}