html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  /* Prevent body from scrolling */
}

body {
  user-select: none;
  /* Prevent text selection */
  -webkit-user-select: none;
  /* Safari */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}