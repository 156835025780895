.App-wrapper {
  text-align: center;
  position: relative;
  /* background: url('../public/bg-pp99-thai-slot.jpeg') no-repeat center center/cover; */
  background-image: linear-gradient(to left top, #1a1b20, #48304d, #913d5c, #cd5745, #de9000);
  height: 100vh;
  /* Fix the height to 100vh */
  overflow: auto;
  /* Allow scrolling within the wrapper */
}

.App-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed for desired darkness */
  z-index: 1;
  /* Ensure overlay is above background */
}

.container-wrap {
  position: relative;
  z-index: 2;
  /* Ensure content is above overlay */
  padding: 20px;
  /* Add some padding */
}

.header {
  text-align: center;
  /* Center align header content */
}

.responsive-img {
  max-width: 100%;
  /* Make sure the image doesn't exceed its container width */
  height: auto;
  /* Maintain the aspect ratio */
}

/* .banner-jackpot {
  padding-top: 100px;
} */

h1,
h2,
h3,
p {
  color: white;
}

.footer {
  p {
    color: gray;
  }
}

.header {
  ul,
  li {
    color: white;
  }
}

.HeaderTitle {
  color: white;
  text-decoration: none;
}