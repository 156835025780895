.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress {
  height: 10px;
}

.progress-bar {
  background-color: rgb(255, 193, 7);
}